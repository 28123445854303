import React, { ReactElement, useCallback, useEffect } from 'react'
import './ContactUs.scss'

import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'

import { addFormValidation } from '../../components/Validators/InputValidations'

const Layout = loadable(() => import('../../components/Layout/Layout'))

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create(option: {
          portalId: string
          formId: string
          target: string
          onFormReady: () => void
        }): void
      }
    }
    jQuery?: {}
  }
}

const ContactUs = (): ReactElement => {
  const handleFormReady = useCallback(() => {
    addFormValidation('contact-form', [
      {
        elementId: 'name-0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'location-0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'contact_name-0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
        validationType: 'numericOnly',
      },
      {
        elementId: 'message-0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
    ])

    // add maxlength in contact number
    const addMaxLengthContacNo: HTMLElement | null = document.getElementById(
      'contact_name-0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
    )

    const isElement = (input: HTMLElement | null): input is HTMLElement =>
      input !== null

    if (isElement(addMaxLengthContacNo)) {
      addMaxLengthContacNo.setAttribute('maxLength', '12')
    }
  }, [])

  useEffect(() => {
    if (window.hbspt?.forms) {
      window.jQuery =
        window.jQuery ||
        ((): { change(): null; trigger(): null } => ({
          change: (): null => null,
          trigger: (): null => null,
        }))

      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '0244ca09-73b2-4c57-b0d7-7ae42a0831d9',
        target: '#contact-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady])

  return (
    <Layout>
      <Seo
        title="Amaia Land Contact Info"
        ogMetaData={{
          description:
            "Whether it'd be an inquiry on property prices, financing schemes or sample computation, you may contact us through filling out this form. We will give you an update within 24 hours. Contact us now!",
        }}
      />

      <div className="contact-us">
        <div className="contact-us-content">
          <h1 className="title">Contact Us</h1>
          <div id="contact-form" />
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
